import { useToast } from "@chakra-ui/react";
import { createBrowserRouter, redirect } from "react-router-dom";
import { Layout } from "../layout";
import { Home } from "../view/Home";
import { Login } from "../view/Login";
import { Profile } from "../view/Profile";
import { Reports } from "../view/Reports";
import { Settings } from "../view/Settings";
import { Stats } from "../view/Stats";
import { Stores } from "../view/Stores";

type Props = {
  isAuthenticated: boolean;
};

export const AppRoutes = ({ isAuthenticated }: Props) => {
  const toast = useToast();

  const routesList = createBrowserRouter([
    {
      path: "/login",
      loader: ({ params, request, context }) => {
        if (isAuthenticated) {
          return redirect("/dashboard");
        } else {
          return null;
        }
      },
      element: <Login />,
    },
    {
      path: "/dashboard",
      element: <Layout />,
      loader: ({ params, request, context }) => {
        if (!isAuthenticated) {
          return redirect("/login");
        } else {
          return null;
        }
      },
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "minhas-lojas",
          element: <Stores />,
        },
        {
          path: "configuracoes",
          element: <Settings />,
        },
        {
          path: "relatorios",
          element: <Reports />,
        },
        {
          path: "estatisticas",
          element: <Stats />,
        },
        {
          path: "meu-perfil",
          element: <Profile />,
        },
      ],
    },
    {
      path: "*",
      loader: () => {
        toast({
          variant: "subtle",
          status: "warning",
          title: "Página não encontrada!",
          description: "A página que você está tentando acessar não existe.",
          duration: 2000,
          isClosable: true,
        });

        if (isAuthenticated) {
          return redirect("/dashboard");
        } else {
          return redirect("/login");
        }
      },
    },
  ]);

  return {
    routesList,
  };
};
