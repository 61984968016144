import { useQuery } from 'react-query';
import { useApi } from './../hooks/useApi';

export const ReportsController = () => {
    const { api } = useApi();

    const reportsQueryKeys = { 
        get: 'getReports'
    };

    const useQueryGetReports = useQuery(reportsQueryKeys.get, () => api.get('/relatoriosbyuser'));

    return {
        reportsQueryKeys,
        useQueryGetReports
    }
}