import { memo, useCallback, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Input as InputChakra,
  Spacer,
  useToast,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";

import { Input } from "../../components/Input";

import { StoresController } from "../../api/StoresController";
import { ReportsController } from "../../api/ReportsController";
import { Loja } from "../../@types/Loja";
import { Relatorio } from "../../@types/Relatorio";
import { Destinatario } from "../../@types/Destinatario";
import { ReceiverController } from "../../api/ReceiverController";
import { Field, Formik } from "formik";
import { AlertPayload } from "../../@types/Alert";
import * as Yup from "yup";
import { AlertsController } from "../../api/AlertsController";
import { useQueryClient } from "react-query";
import { ReportOptionCard } from "../ModalAddReport/_ReportOptionCard";
import { stringFormat } from "../../utils/stringFormat";

type Props = {
  alertId: number;
  isOpen: boolean;
  onClose: () => void;
};

export const ModalEditReport = memo(({ alertId, isOpen, onClose }: Props) => {
  const [reportsSelected, setReportsSelected] = useState<number[]>([]);
  const queryClient = useQueryClient();

  // import dos hooks de query
  const { useQueryGetStores } = StoresController();
  const { useQueryGetReports } = ReportsController();
  const { useQueryGetReceivers } = ReceiverController();
  const { useMutationEditAlert, useQueryGetAlertById } = AlertsController();
  
  // calls das queries
  const { data: alert, isLoading: isLoadingAlert } = useQueryGetAlertById(alertId);
  const { data: receivers, isLoading: isLoadingReceivers } =
  useQueryGetReceivers;
  const { data: reports, isLoading: isLoadingReports } = useQueryGetReports;
  const { data: stores, isLoading: isLoadingStores } = useQueryGetStores;

  const isLoadingContent = useMemo(() => {
    return isLoadingAlert || isLoadingReceivers || isLoadingReports || isLoadingStores
  }, [isLoadingAlert, isLoadingReceivers, isLoadingReports, isLoadingStores])
  

  const toast = useToast();

  type ReportOption = {
    value: number,
    title: string,
    description: string,
    isChecked: boolean
  }
  
  const reportOptions = useMemo(() => {
    if (!reports?.data) return [];

    return reports?.data.map((report: Relatorio) => ({
      value: report.id,
      title: report.titulo,
      description: report.descricao,
      isChecked: reportsSelected.includes(report.id),
    }));
  }, [reports, reportsSelected]);

  const initialValues = useMemo(() => {
    if (!alert?.data) return {};

    setReportsSelected(alert?.data?.relatorios);

    return {
      id: alert?.data.id,
      hora: alert?.data.hora,
      id_destinatario: alert?.data.id_destinatarios,
      id_loja: alert?.data.id_loja,
    };
  }, [alert]);


  const handleSelectReport = useCallback(
    (value: number) => {
      if (reportsSelected.includes(value)) {
        setReportsSelected(reportsSelected.filter((item) => item !== value));
      } else {
        setReportsSelected((prev) => [...prev, value]);
      }
    },
    [reportsSelected, setReportsSelected]
  );

  const handleClearForm = useCallback(() => {
    setReportsSelected([]);
  }, []);

  const submitForm = useCallback(
    (data: any, formik: any) => {
      const dataToSend: AlertPayload = { ...data };
      dataToSend.relatorios = reportsSelected;
      dataToSend.id = alertId;

      if (reportsSelected.length < 1) {
        toast({
          title: "Atenção",
          description: "Selecione ao menos um relatório",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        return;
      }

      useMutationEditAlert.mutateAsync(dataToSend, {
        onSuccess: () => {
          toast({
            title: "Sucesso",
            description: "Alerta editado com sucesso",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries("getAlerts");
          onClose();
        },
      });
    },
    [
      reportsSelected,
      toast,
      useMutationEditAlert,
      queryClient,
      alertId,
      onClose,
    ]
  );

  const validationSchema = useMemo(() => {
    const requiredMessage = "Campo Obrigatório";
    return Yup.object({
      id_destinatario: Yup.number().required(requiredMessage),
      id_loja: Yup.number().required(requiredMessage),
      hora: Yup.string().required(requiredMessage),
    });
  }, []);

  

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar relatório</ModalHeader>
        <ModalCloseButton />
        {isLoadingContent ? (
          <Flex p={6} justifyContent={'center'}>
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Formik
            onSubmit={submitForm}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ handleSubmit, errors, touched, values }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <ModalBody>
                    <Stack spacing={3}>
                      <FormControl>
                        <FormLabel fontWeight={"normal"}>
                          Destinatário
                        </FormLabel>
                        <Field
                          as={Select}
                          h={12}
                          id="id_destinatario"
                          name="id_destinatario"
                          variant="filled"
                          placeholder="Selecione o destinatário"
                          value={values.id_destinatario}
                        >
                          {receivers?.data?.map((receiver: Destinatario) => (
                            <option key={receiver.id} value={receiver.id}>{`${
                              receiver.nome
                            } - ${stringFormat(
                              "phone",
                              receiver.telefone
                            )}`}</option>
                          ))}
                        </Field>
                      </FormControl>

                      <FormControl>
                        <FormLabel fontWeight={"normal"}>
                          Escolha uma loja
                        </FormLabel>
                        <Field
                          as={Select}
                          h={12}
                          variant="filled"
                          placeholder="Selecione uma loja"
                          name="id_loja"
                          id="id_loja"
                          value={values.id_loja}
                        >
                          {stores?.data.map((store: Loja) => (
                            <option key={store.id} value={store.id}>
                              {store.descricao}
                            </option>
                          ))}
                        </Field>
                      </FormControl>

                      <Stack>
                        <Text>Selecione o(s) relatório(s)</Text>

                        {reportOptions.map((reportOption: ReportOption) => (
                          <ReportOptionCard
                            key={reportOption.value}
                            {...reportOption}
                            onClick={() =>
                              handleSelectReport(reportOption.value)
                            }
                          />
                        ))}
                      </Stack>

                      <FormControl>
                        <FormLabel fontWeight={"normal"}>
                          Horário de envio
                        </FormLabel>
                        <Field as={Input} name="hora" id="hora" type="time" />
                      </FormControl>
                    </Stack>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      mr={3}
                      variant="ghost"
                      colorScheme="transparent"
                      onClick={handleClearForm}
                    >
                      Limpar
                    </Button>
                    <Spacer />
                    <Button mr={3} variant="ghost" onClick={onClose}>
                      Cancelar
                    </Button>

                    <Button colorScheme="blue" type="submit">
                      Salvar
                    </Button>
                  </ModalFooter>
                </form>
              </>
            )}
          </Formik>
        )}
      </ModalContent>
    </Modal>
  );
});
