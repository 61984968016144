import { memo } from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack, Tooltip } from "@chakra-ui/react";

import { NavMenu } from "../components/NavMenu";
import { ColorModeSwitcher } from "../ColorModeSwitcher";

export const Layout = memo(() => {
  return (
    <Stack direction="row" w="full" h="full" spacing={0}>
      <NavMenu />
      <Stack w="full" overflowY="auto">
        <Outlet />
      </Stack>

      <Tooltip hasArrow placement="top-end" label="Alterar tema" mb={1}>
        <Box position="fixed" bottom={6} right={6}>
          <ColorModeSwitcher />
        </Box>
      </Tooltip>
    </Stack>
  );
});
