import { useQuery } from "react-query";
import { useApi } from "../hooks/useApi"

export const ReceiverController = () => {
    const { api } = useApi();

    const receiverQueryKeys = {
        get: 'getReceivers'
    }

    const useQueryGetReceivers = useQuery(receiverQueryKeys.get, () => api.get('/destinatarios'));

    return {
        receiverQueryKeys,
        useQueryGetReceivers
    }
}