import { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { Alert, AlertPayload } from "../@types/Alert";
import { useApi } from "../hooks/useApi";

/* export const AlertsController = () => {
    const { api } = useApi();

    // funções de fetch
    const getAlertsByUser = async () => {
        return await api.get('/alertasbyuser');
    }

    const getAlertById = async (id: number) => {
        return await api.get(`/alerta/${id}`).then((res) => res.data);
    }
 
    const saveAlert = async (data: AlertPayload) =>{
        return await api.post('/alerta',data);
    }

    const editAlert = async (data: AlertPayload) => {
        return await api.put(`/alerta/${data.id}`, {...data})
    }

    const deleteAlert = async (id: number) => {
        return await api.delete(`/alerta/${id}`);
    }

    // funções react-query para usar
    const queryGetAlerts = useQuery('getAlerts', getAlertsByUser);
    const useQueryGetAlertById = (id: number) => useQuery(['getAlertById', id], () => getAlertById(id))
    const useMutationSaveAlert = useMutation(saveAlert);
    const useMutationEditAlert = useMutation({
        mutationFn: (data:AlertPayload) => editAlert(data)
    })
    const useMutationDeleteAlert = useMutation(deleteAlert);

    // export de get com queryKey
    const useQueryGetAlerts = { queryGetAlerts, queryKey: 'getAlerts'}

    return {
        useQueryGetAlerts,
        useQueryGetAlertById,
        useMutationSaveAlert,
        useMutationEditAlert,
        useMutationDeleteAlert
    };
} */

export const AlertsController = () => {
  const { api } = useApi();

  const alertsQueryKeys = {
    get: "getAlerts",
    getById: "getAlertbyId",
    post: "saveAlert",
    put: "editAlert",
    delete: "deleteAlert",
  };

  const useQueryGetAlerts = useQuery(alertsQueryKeys.get, () =>
    api.get("/alertasbyuser")
  );
  const useQueryGetAlertById = (id: number) =>
    useQuery([alertsQueryKeys.getById, id], () => api.get(`/alerta/${id}`));
  const useMutationSaveAlert = useMutation({
      mutationFn: (data:AlertPayload) => api.post("/alerta", data),
    });
  const useMutationEditAlert = useMutation({
      mutationFn: (data: AlertPayload) => api.put(`/alerta/${data.id}`, { ...data }),
    });
  const useMutationDeleteAlert = useMutation({
      mutationFn: (id: number) => api.delete(`/alerta/${id}`),
    });

  return {
    alertsQueryKeys,
    useQueryGetAlerts,
    useQueryGetAlertById,
    useMutationSaveAlert,
    useMutationEditAlert,
    useMutationDeleteAlert,
  };
};
