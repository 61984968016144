import { useQuery } from "react-query";
import { useApi } from "../hooks/useApi"

export const StoresController = () => {
    const { api } = useApi();

    const storesQueryKeys = {
        get: 'getLojas'
    }

    const useQueryGetStores = useQuery(storesQueryKeys.get, () => api.get('/lojasbyuser'));

    return {
        storesQueryKeys,
        useQueryGetStores
    }
}