import { RouterProvider } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { AppRoutes } from "./app.routes";

export const Routes = () => {
  const { isAuthenticated } = useAuth();
  const { routesList } = AppRoutes({ isAuthenticated });

  return <RouterProvider router={routesList} />;
};
