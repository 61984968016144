import axios from "axios";
import { useStorage } from "./useStorage";

export const useApi = () => {
  const { getItem, setItem } = useStorage();

  const api = axios.create({
    baseURL: "https://james.wopen.com.br:8000/api"
  });

  // função para dar refresh no token
  // eslint-disable-next-line
  const refreshToken = async (originalRequest:any) => {
    api.post('/refresh')
      .then((res) => {
        if (res.data.access_token){
          setItem("@JAMES:token", res.data.access_token);
          originalRequest.headers["Authorization"] = `Bearer ${res.data.access_token}`;
          return api(originalRequest);
        }
      })
      .catch((err) => {
        console.error('erro ao atualizar token: ', err);
      })
  }

  // interceptor pra add o token correto
  api.interceptors.request.use((config) => {
    const token = getItem('@JAMES:token');
    config.headers.Authorization = `Bearer ${token}`

    return config;
  }, (err) => Promise.reject(err));
  
  // interceptor para dar refresh no token jwt
  
  api.interceptors.response.use(async (response) => {
    return response
  }, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401){
      return await refreshToken(originalRequest);
    }

    return Promise.reject(error);
  })

  return { api };
};
