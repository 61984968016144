const strToPhone = (value: string) => {
    if(value.length === 13){
        return `${value.substring(0,2)} (${value.substring(2,4)}) ${value.substring(4,9)}-${value.substring(9)}` 
    }

    return `${value.substring(0,2)} (${value.substring(2,4)}) ${value.substring(4,8)}-${value.substring(8)}` 
}

export const stringFormat = (type: string, value: string) => {
    switch (type){
        case 'phone':
            return strToPhone(value);
        default:
            return value;
    }
}