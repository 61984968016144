import { memo } from "react";
import {
  Avatar,
  Button,
  Divider,
  HStack,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Storefront,
  SquaresFour,
  Gear,
  Scroll,
  ChartLineUp,
  User,
  SignOut,
  ArrowRight,
} from "phosphor-react";

import { NavLink } from "./_NavLink";
import { useAuth } from "../../contexts/AuthContext";

export const NavMenu = memo(() => {
  const { onSignOut } = useAuth();

  const links = [
    {
      Icon: SquaresFour,
      label: "Dashboard",
      path: "/dashboard",
    },
    {
      Icon: Storefront,
      label: "Minhas Lojas",
      path: "minhas-lojas",
    },
    {
      Icon: Scroll,
      label: "Relatórios",
      path: "relatorios",
    },
    {
      Icon: ChartLineUp,
      label: "Estatísticas",
      path: "estatisticas",
    },
    {
      Icon: Gear,
      label: "Configurações",
      path: "configuracoes",
    },
    {
      Icon: User,
      label: "Meu perfil",
      path: "meu-perfil",
    },
  ];

  return (
    <Stack
      w="full"
      maxW="xs"
      h="full"
      bgColor={useColorModeValue("gray.100", "gray.700")}
      spacing={0}
    >
      <HStack p={6} pr={4} gap={2}>
        <Avatar src="https://i.pravatar.cc/300?img=13" name="Luiz Henrique" />
        <Stack spacing={-1}>
          <Text fontSize="lg" fontWeight="bold" noOfLines={1}>
            Jeremy Renner
          </Text>
          <Text fontSize="xs" color="gray.500" noOfLines={1}>
            jeremy.renner18@gmail.com
          </Text>
        </Stack>
      </HStack>

      <Stack flex={1} p={6} pt={0} gap={1}>
        {links.map((link) => (
          <NavLink
            key={link.path}
            Icon={link.Icon}
            label={link.label}
            path={link.path}
          />
        ))}
        <Divider />

        <Button
          w="full"
          leftIcon={<SignOut size={18} weight="fill" />}
          variant="ghost"
          justifyContent="flex-start"
          onClick={onSignOut}
        >
          Sair
        </Button>

        <Spacer />

        <Stack bgColor="blue.300" rounded={14} p={4} py={6} gap={3} shadow="xs">
          <Stack spacing={-1} _light={{ color: "gray.700" }}>
            <Text fontSize="xl" fontWeight="bold">
              Precisa de ajuda?
            </Text>
            <Text fontSize="sm" opacity={0.8}>
              Entre em contato com o suporte.
            </Text>
          </Stack>
          <Button
            rightIcon={<ArrowRight />}
            bgColor="gray.700"
            transition="all 0.2s"
            justifyContent="space-between"
            _light={{
              bgColor: "whiteAlpha.500",
              _hover: { bgColor: "gray.200" },
            }}
            _hover={{ bgColor: "gray.800" }}
            _active={{ transform: "scale(0.95)" }}
          >
            Suporte
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
});
