import { MagnifyingGlass, Plus } from "phosphor-react";
import {
  Button,
  Divider,
  Grid,
  Heading,
  HStack,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";

import { InputWithIcon } from "../../components/InputWithIcon";
import { ModalAddReport } from "../../modals/ModalAddReport";
import { ReportCard } from "../../components/ReportCard";
import { memo, useState } from "react";
import { AlertsController } from "../../api/AlertsController";
import { Alert } from "../../@types/Alert";
import { ModalEditReport } from "../../modals/ModalEditReport";

export const Reports = memo(() => {
  const {
    isOpen: isOpenModalAddReport,
    onOpen: onOpenModalAddReport,
    onClose: onCloseModalAddReport,
  } = useDisclosure();

  const {
    isOpen: isOpenModalEditReport,
    onOpen: onOpenModalEditReport,
    onClose: onCloseModalEditReport
  } = useDisclosure();

  const [editAlertId, setEditAlertId] = useState(0);

  const { useQueryGetAlerts } = AlertsController();
  
  const { data: alerts } = useQueryGetAlerts;

  return (
    <Stack w="full" h="full" flex={1} spacing={0}>
      <HStack w="full" p={6} justify="space-between">
        <Heading fontSize="2xl">Relatórios</Heading>
        <HStack>
          <InputWithIcon
            leftIcon={<MagnifyingGlass />}
            type="text"
            placeholder="Buscar relatório..."
            transition="all 0.5s ease-in-out"
            _focus={{ w: "360px" }}
          />

          <Button
            leftIcon={<Plus />}
            minW="max-content"
            type="button"
            colorScheme="blue"
            onClick={onOpenModalAddReport}
          >
            Novo relatório
          </Button>
        </HStack>
      </HStack>
      <Divider />

      <Grid
        p={6}
        gridTemplateColumns="repeat(auto-fill, minmax(300px, 320px))"
        gap={6}
      >
        {alerts?.data.map((alert: Alert) => (
          <ReportCard 
            key={alert.id} 
            setEditAlertId={setEditAlertId} 
            onOpenModalEditReport={onOpenModalEditReport}
            alert={alert} 
          />
        ))}
      </Grid>

      {isOpenModalAddReport && (
        <ModalAddReport
        isOpen={isOpenModalAddReport}
        onClose={onCloseModalAddReport}
      />
      )}

      {
        isOpenModalEditReport && (
          <ModalEditReport
            alertId={editAlertId}
            isOpen={isOpenModalEditReport}
            onClose={onCloseModalEditReport}
          />
        )
      }
      
    </Stack>
  );
});
