import { AuthCredentials } from "../@types/AuthCredentials";
import { useApi } from "./../hooks/useApi";

export const AuthController = () => {
  const { api } = useApi();

  const signIn = async ({email, password}:AuthCredentials) => {
    const response = await api.post("/login", {
      email,
      password,
    });

    return response;
  };

  const signOut = async () => {
    const response = await api.post("/logout");

    return response;
  }

  signIn.queryKey = 'login';
  signOut.queryKey = 'logout';

  return {
    signIn,
    signOut,
  };
};
