import { memo } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";

type Props = InputProps & {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

export const InputWithIcon = memo(
  ({ leftIcon: LeftIcon, rightIcon: RightIcon, ...rest }: Props) => {
    return (
      <InputGroup>
        {LeftIcon && <InputLeftElement>{LeftIcon}</InputLeftElement>}
        <Input variant="filled" {...rest} />

        {RightIcon && <InputRightElement>{RightIcon}</InputRightElement>}
      </InputGroup>
    );
  }
);
