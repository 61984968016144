import { memo } from "react";
import { Check } from "phosphor-react";
import {
  Center,
  HStack,
  ScaleFade,
  Stack,
  Text,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";

import { hexToRgba } from "../../utils/hexToRgba";

type Props = {
  value: number;
  title: string;
  description: string;
  isChecked: boolean;
  onClick: () => void;
};

export const ReportOptionCard = memo(
  ({ value, title, description, isChecked, onClick }: Props) => {
    const { colors } = useTheme();

    const optionDetailColor = useColorModeValue(
      colors.blue[500],
      colors.blue[200]
    );
    const optionDetailBgColor = useColorModeValue("gray.100", "whiteAlpha.50");
    const optionDetailIconColor = useColorModeValue("white", "black");
    const optionDetailIconBgColor = useColorModeValue(
      "gray.300",
      "whiteAlpha.50"
    );

    return (
      <HStack
        key={value}
        px={4}
        py={3}
        pos="relative"
        bgColor={optionDetailBgColor}
        borderRadius={4}
        borderWidth={1}
        borderColor={isChecked ? optionDetailColor : "transparent"}
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        boxShadow={
          isChecked ? `0 0 0 2px ${hexToRgba(optionDetailColor, 0.2)}` : "none"
        }
        _hover={{
          borderColor: optionDetailColor,
        }}
        onClick={onClick}
      >
        <Stack spacing={-1}>
          <Text fontWeight="bold" letterSpacing="wide">
            {title}
          </Text>
          <Text fontSize="sm" opacity={0.5}>
            {description}
          </Text>
        </Stack>

        <Center
          w={4}
          h={4}
          pos="absolute"
          top={3}
          right={3}
          rounded={3}
          bgColor={isChecked ? optionDetailColor : optionDetailIconBgColor}
        >
          <ScaleFade in={isChecked} delay={{ enter: 0.1 }}>
            <Check size={12} weight="bold" color={optionDetailIconColor} />
          </ScaleFade>
        </Center>
      </HStack>
    );
  }
);
