import { memo } from "react";
import { Button, Text } from "@chakra-ui/react";
import { NavLink as Link } from "react-router-dom";
import { IconProps } from "phosphor-react";

type Props = {
  Icon: React.ElementType<IconProps>;
  label: string;
  path: string;
};

export const NavLink = memo(({ Icon, label, path }: Props) => {
  return (
    <Link to={path} end>
      {({ isActive }) => (
        <Button
          role="group"
          w="full"
          leftIcon={<Icon size={18} weight="fill" color="currentColor" />}
          variant="ghost"
          justifyContent="flex-start"
          color={isActive ? "blue.300" : "currentColor"}
        >
          <Text>{label}</Text>
        </Button>
      )}
    </Link>
  );
});
