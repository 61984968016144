import { memo } from "react";
import { Trash } from "phosphor-react";
import {
  Button,
  Center,
  Circle,
  HStack,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";

import { hexToRgba } from "../../utils/hexToRgba";

type Props = {
  onClose: () => void;
  onConfirm?: () => void;
  onDelete: (id: number) => Promise<void>;
  id: number
};

export const OverlayConfirmDelete = memo(({ onClose, onDelete, id }: Props) => {
  const { colors } = useTheme();

  return (
    <Center
      pos="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgColor={hexToRgba(colors.red[600], 0.85)}
    >
      <Stack alignItems="center" spacing={3}>
        <Circle
          size={20}
          bgColor="white"
          boxShadow={`0 0 0 16px ${hexToRgba("#FFFFFF", 0.1)}`}
        >
          <Trash size={32} weight="duotone" color={colors.red[600]} />
        </Circle>

        <Text px={10} textAlign="center" color="white">
          Você deseja realmente excluir esse relatório?
        </Text>

        <HStack>
          <Button
            variant="ghost"
            colorScheme="whiteAlpha"
            color="white"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="transparent"
            bgColor="white"
            color="red.600"
            _active={{
              transform: "scale(0.95)",
            }}
            onClick={() => onDelete(id)}
          >
            Excluir
          </Button>
        </HStack>
      </Stack>
    </Center>
  );
});
