import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  config: {
    initialColorMode: "ligth",
    useSystemColorMode: true,
  },
  styles: {
    global: {
      "*": {
        boxSizing: "border-box",
        margin: "0",
        padding: 0,
      },

      body: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        overflow: "hidden",
      },

      "#root": {
        flex: 1,
      },

      "::-webkit-scrollbar": {
        width: "4px",
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
      },

      "::-webkit-scrollbar-thumb": {
        background: "whiteAlpha.300",
        borderRadius: "3px",
      },

      "::-webkit-scrollbar-thumb:hover": {
        background: "whiteAlpha.500",
      },
    },
  },
});
