import { createContext, useContext, useState, useCallback, useEffect } from "react";
import { AuthCredentials } from "../@types/AuthCredentials";
import { AuthController } from "../api/AuthController";
import { useStorage } from '../hooks/useStorage'
type AuthContextType = {
  isAuthenticated: boolean;
  onSignIn: (data:AuthCredentials) => void;
  onSignOut: () => void;
};

const AuthContext = createContext({} as AuthContextType);

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { signIn, signOut } = AuthController();
  const { setItem, removeItem, getItem } = useStorage();  

  const onSignIn = useCallback((data:AuthCredentials) => {
    
    if(data.lembrar){
      setItem('@JAMES:lembrarEmail', true);
      setItem('@JAMES:ultimoEmail', data.email);
    } else {
      removeItem('@JAMES:lembrarEmail');
      removeItem('@JAMES:ultimoEmail');
    }

    return signIn(data)
      .then((res:any) => {
        const token = res?.data?.authorisation[0]?.original?.access_token || null;
        if (token){
          setItem('@JAMES:token', token)
          setIsAuthenticated(true);
          return true
        }
      })
      .catch((err) => {
        console.error('erro login: ', err);
        return err;
      });

  }, [signIn, setItem, removeItem]);

  const onSignOut = useCallback(async () => {
    await signOut()
    setIsAuthenticated(false);
    removeItem('@JAMES:token');
  }, [removeItem, signOut]);

  useEffect(() => {
    let token = null 
    token = getItem('@JAMES:token');
    
    token ? setIsAuthenticated(true) : setIsAuthenticated(false);
  
    // eslint-disable-next-line
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        onSignIn,
        onSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
