import {
  HStack,
  Heading,
  Stack,
  VStack,
  FormControl,
  FormLabel,
  Button,
  Text,
  Switch,
  InputGroup,
  InputRightElement,
  IconButton,
  useBoolean,
  Tooltip,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { useAuth } from "../../contexts/AuthContext";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { Input } from "../../components/Input";
import { useCallback, useMemo, useState } from "react";

import * as Yup from "yup";
import { Field, Formik } from "formik";
import { AuthCredentials } from "../../@types/AuthCredentials";
import { useStorage } from "../../hooks/useStorage";

export const Login = () => {
  const { onSignIn } = useAuth();
  const { getItem } = useStorage();

  const [showPass, setShowPass] = useBoolean();
  const [errorMessage, setErrorMessage] = useState(null);

  const initialValues = useMemo(() => {
    return {
      email: getItem("@JAMES:ultimoEmail") || "",
      password: "",
      lembrar: Boolean(getItem("@JAMES:lembrarEmail")) || false
    };
  }, [getItem]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string()
        .required("Campo obrigatório")
        .email("E-mail inválido"),
      password: Yup.string().required("Campo obrigatório"),
    });
  }, []);


  const handleSubmitForm = useCallback(
    async (data: AuthCredentials) => {
      const res: any = await onSignIn(data);
      const {
        response: { data: responseData },
      } = res;

      if (responseData.status === "error") {
        setErrorMessage(responseData.message);
      }
    },
    [onSignIn]
  );

  return (
    <VStack w="full" h="full" justifyContent="center">
      <HStack w="full" maxW="7xl" justifyContent="center">
        <Stack flex={1} maxW="xs" gap={4}>
          <VStack>
            <Heading as="h1" size="2xl">
              Login
            </Heading>

            <Text fontSize="sm">Faça login para acessar a sua conta.</Text>
          </VStack>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {({ handleSubmit, errors, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <VStack spacing={4} align={"flex-start"}>
                  <FormControl isInvalid={!!errors.email && touched.email}>
                    <FormLabel>E-mail</FormLabel>
                    <Field
                      as={Input}
                      id="email"
                      name="email"
                      type="email"
                      variant="filled"
                      placeholder={"Digite seu e-mail"}
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={!!errors.password && touched.password}
                  >
                    <FormLabel>Senha</FormLabel>
                    <InputGroup>
                      <Field
                        as={Input}
                        id="password"
                        name="password"
                        type={showPass ? "text" : "password"}
                        placeholder="••••••••"
                      />
                      <InputRightElement h="full">
                        <Tooltip
                          hasArrow
                          placement="top"
                          label={showPass ? "Ocultar senha" : "Mostrar senha"}
                        >
                          <IconButton
                            size="xs"
                            aria-label="Toggle visibility password"
                            colorScheme="whiteAlpha"
                            variant="ghost"
                            color="gray.500"
                            icon={
                              showPass ? (
                                <FaEyeSlash size={16} />
                              ) : (
                                <FaEye size={16} />
                              )
                            }
                            onClick={setShowPass.toggle}
                          />
                        </Tooltip>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <HStack>
                      <Field as={Switch} id="lembrar" name="lembrar" type="checkbox" isChecked={values.lembrar} />
                      <FormLabel>Lembrar de mim </FormLabel>
                    </HStack>
                  </FormControl>

                  <Button
                    variant="unstyled"
                    colorScheme="blue"
                    bgGradient="linear(to-r, cyan.300 5%, blue.500 95%)"
                    color="white"
                    type="submit"
                    width="full"
                    _hover={{ transform: "scale(1.02)" }}
                    _active={{ transform: "scale(1)" }}
                  >
                    ENTRAR
                  </Button>

                  {errorMessage && (
                    <Alert status="error" flexDirection={'row'} justifyContent={'space-around'}>
                      <AlertIcon />
                      <Box display='flex' flexDirection='column' alignItems='center'>
                        <AlertTitle>Erro</AlertTitle>
                        <AlertDescription>{errorMessage}</AlertDescription>
                      </Box>
                      <CloseButton
                        alignSelf="flex-start"
                        position="relative"
                        right={-1}
                        top={-1}
                        onClick={() => setErrorMessage(null)}
                      />
                    </Alert>
                  )}
                </VStack>
              </form>
            )}
          </Formik>

          <ColorModeSwitcher hidden />
        </Stack>
      </HStack>
    </VStack>
  );
};
