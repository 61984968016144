import { Dispatch, memo, Ref, SetStateAction, useCallback } from "react";
import { DotsThree, Pencil, Storefront, Trash } from "phosphor-react";
import {
  Circle,
  Fade,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  useBoolean,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import { OverlayConfirmDelete } from "./_OverlayConfirmDelete";
import { Alert } from "../../@types/Alert";
import { AlertsController } from "../../api/AlertsController";
import { useQueryClient } from "react-query";
import { stringFormat } from "../../utils/stringFormat";

type Props = {
  alert: Alert,
  onOpenModalEditReport: () => void,
  setEditAlertId: Dispatch<SetStateAction<number>>
}

export const ReportCard = memo(({alert, onOpenModalEditReport, setEditAlertId}: Props) => {
  const [confirmDelete, confirmOverlay] = useBoolean(false);

  const bgColorCard = useColorModeValue("gray.100", "gray.700");
  const bgColorIcon = useColorModeValue("gray.300", "gray.600");
  const colorIcon = useColorModeValue("black", "white");

  const queryClient = useQueryClient();
  const { useMutationDeleteAlert, useQueryGetAlerts, alertsQueryKeys } = AlertsController();
  const toast = useToast();
  
  const handleDelete = useCallback(async(id: number) => {
    useMutationDeleteAlert.mutateAsync(id, {
      onSuccess: () => {
        toast({
          title: 'Sucesso',
          description: 'Alerta removido!',
          status: 'success',
          duration: 2500,
          isClosable: true
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(alertsQueryKeys.get)
      }
    })
  }, [useMutationDeleteAlert, alertsQueryKeys, toast, queryClient])

  const handleEditReport = useCallback(() => {
    setEditAlertId(alert.id)
    onOpenModalEditReport()
  }, [alert, onOpenModalEditReport, setEditAlertId])

  return (
    <Stack
      p={6}
      w="full"
      bgColor={bgColorCard}
      rounded="md"
      pos="relative"
      spacing={0}
      overflow="hidden"
    >
      <HStack w="full" spacing={3}>
        <Circle size={10} bgColor={bgColorIcon}>
          <Storefront size={24} weight="duotone" color={colorIcon} />
        </Circle>
        <Stack spacing={-1}>
          <Text fontSize="xs" textTransform="uppercase">
            Loja
          </Text>
          <Text fontWeight="bold" textTransform="uppercase" noOfLines={1}>
            {alert.loja.descricao}
          </Text>
        </Stack>

        <Spacer />

        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            size="sm"
            aria-label="Opções"
            variant="ghost"
            icon={<DotsThree size={24} weight="duotone" color={colorIcon} />}
          />
          <MenuList>
            <MenuItem
              icon={<Pencil size={18} weight="duotone" color={colorIcon} />}
              onClick={() => handleEditReport()}
            >
              Editar relatório
            </MenuItem>
            <MenuItem
              icon={<Trash size={18} weight="duotone" color={colorIcon} />}
              onClick={confirmOverlay.on}
            >
              Excluir relatório
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      <Stack py={4}>
        <Stack spacing={0}>
          <Text fontSize="xs" letterSpacing="wide" opacity={0.8}>
            Destinatário
          </Text>
          <Text fontWeight="semibold" letterSpacing="wide">
            {alert.destinatario.nome}
          </Text>
        </Stack>

        <Stack spacing={0}>
          <Text fontSize="xs" letterSpacing="wide" opacity={0.8}>
            Contato
          </Text>
          <Text fontWeight="semibold" letterSpacing="wide">
            {stringFormat('phone', alert.destinatario.telefone)}
          </Text>
        </Stack>

        <Stack spacing={0}>
          <Text fontSize="xs" letterSpacing="wide" opacity={0.8}>
            Horario de envio
          </Text>
          <Text fontWeight="semibold" letterSpacing="wide">
            {alert.hora}
          </Text>
        </Stack>

        <Stack spacing={0}>
          <Text fontSize="xs" letterSpacing="wide" opacity={0.8}>
            Relatório(s)
          </Text>
          <Text fontWeight="semibold" lineHeight="100%" letterSpacing="wide">
            {alert.relatorios.map(rel => rel.titulo).join(', ')}
          </Text>
        </Stack>
      </Stack>

      <Fade in={confirmDelete} hidden={!confirmDelete}>
        <OverlayConfirmDelete id={alert.id} onDelete={handleDelete} onClose={confirmOverlay.off} />
      </Fade>
    </Stack>
  );
});
